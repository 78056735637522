"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.linkResolver = void 0;
const linkResolver = (doc) => {
    // URL for a category type
    if (doc.type === 'workcollection') {
        return `/collections/${doc.uid}`;
    }
    if (doc.type === 'work') {
        return `/works/${doc.uid}`;
    }
    if (doc.type === 'cvpage') {
        return `/`;
    }
    if (doc.type === 'workplace') {
        return `/workplace`;
    }
    if (doc.type === 'contact') {
        return `/contact`;
    }
    // Backup for all other types
    return '/';
};
exports.linkResolver = linkResolver;
