import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';

import * as $ from './header.module.css';
const Series = (props: {
  collections: GatsbyTypes.CollectionsFragment['nodes'];
  title: string;
}) => (
  <div className="flex flex-col sm:flex-row">
    <div className="mb-4 sm:mb-8 md:mb-14">
      {props.collections.map((collection) => (
        <Link
          to={`/collections/${collection.uid}`}
          key={collection.id}
          activeClassName="font-bold"
          partiallyActive={true}
          className="block leading-normal text-lg font-light uppercase text-gray-800 hover:text-gray-900 transition linear duration-100"
        >
          {collection.data?.title}
        </Link>
      ))}
    </div>
  </div>
);

const Header: React.FC<{
  siteTitle: string;
  title?: string | null | undefined;
}> = ({ siteTitle, title }) => {
  const data = useStaticQuery<GatsbyTypes.HeaderQueryQuery>(graphql`
    fragment Collections on PrismicWorkcollectionConnection {
      nodes {
        id
        uid
        type
        tags
        data {
          title
        }
      }
    }
    query HeaderQuery {
      series: allPrismicWorkcollection(filter: { tags: { in: ["series"] } }) {
        ...Collections
      }
      projects: allPrismicWorkcollection(
        filter: { tags: { in: ["project"] } }
      ) {
        ...Collections
      }
    }
  `);

  const series = data.series.nodes;
  const projects = data.projects.nodes;

  return (
    <div
      className={`container mx-auto px-4 pt-4 sm:pt-10 sm:pb-10 md:pb-12 ${$.maoriNav}`}
    >
      <header className="md:inline-flex flex-col">
        <div className="flex  items-center  mb-4 sm:mb-8 md:mb-14">
          <h1 className="text-gray-900 text-2xl sm:text-4xl md:text-5xl mr-2    ">
            <Link to="/">{siteTitle}</Link>
          </h1>
          <label htmlFor="menu" className="ml-auto sm:hidden cursor-pointer">
            <span className="flex items-center px-3 py-2 border rounded ">
              <svg
                className="fill-current h-3 w-3"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </span>
          </label>
        </div>
        <input type="checkbox" id="menu" tabIndex={-1} defaultChecked={true} />

        <nav role="off-canvas" className="mb-4 md:mb-0 hidden sm:block">
          {series.length > 0 && <Series collections={series} title="Series" />}
          {projects.length > 0 && (
            <Series collections={projects} title="Projects" />
          )}
          <hr className="mb-4 sm:mb-8 md:mb-14 border-black" />
          <Link
            activeClassName="font-bold"
            className="block leading-normal text-lg font-light uppercase text-gray-800 hover:text-gray-900 transition linear duration-100"
            to="/cv"
          >
            CV
          </Link>
          {/*          <Link
            activeClassName="font-bold"
            className="block leading-normal text-lg font-light uppercase text-gray-800 hover:text-gray-900 transition linear duration-100"
            to="/workplace"
          >
            WorkPlace
          </Link>*/}
          <Link
            activeClassName="font-bold"
            className="block leading-normal text-lg font-light uppercase text-gray-800 hover:text-gray-900 transition linear duration-100"
            to="/contact"
          >
            Contact
          </Link>
        </nav>
      </header>
    </div>
  );
};

export default Header;
