import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import './layout.css';
import Header from './header';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
const Layout: React.FC<{ title?: string | null | undefined }> = ({
  title,
  children,
}) => {
  return (
    <StaticQuery<GatsbyTypes.SiteTitleQueryQuery>
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => (
        <div className="flex flex-col min-h-screen">
          <Header
            siteTitle={data?.site?.siteMetadata?.title ?? ''}
            title={title}
          />
          <div className="container mx-auto px-4 flex-1">
            <main className="flex-1">
              {title && (
                <div className="mb-4">
                  <p className="inline-block leading-relaxed text-lg font-normal uppercase text-gray-800 hover:text-gray-700 transition linear duration-100 my-4">
                    {title}
                  </p>
                </div>
              )}
              {children}
            </main>
          </div>
          <footer className="container mx-auto px-4 mb-4 sm:mb-10 md:mb-16 mt-8 sm:mt-16 md:mt-26 leading-tight text-sm text-center flex">
            <span>© {new Date().getFullYear()} Maori</span>
            <div className="ml-auto flex space-x-4 underline">
              <OutboundLink
                target="_blank"
                href="https://www.instagram.com/margarita.semerikova/"
              >
                Instagram
              </OutboundLink>
              <OutboundLink
                target="_blank"
                href="https://www.facebook.com/mazayka"
              >
                Facebook
              </OutboundLink>
            </div>
          </footer>
        </div>
      )}
    />
  );
};

export default Layout;
