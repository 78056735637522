// src/pages/preview.js

import * as React from 'react';
import { withPreviewResolver } from 'gatsby-source-prismic';

import Layout from '../components/layout';
import { IPageProps } from '../types/page-props';
import { linkResolver } from '../utils/linkResolver';

const PreviewPage: React.FC<
  IPageProps & { isPreview: boolean; isLoading: boolean }
> = (props) => {
  if (!props.isPreview) return <>Not a preview!</>;

  return (
    <Layout>
      <p>Loading</p>
    </Layout>
  );
};

export default withPreviewResolver(PreviewPage, {
  repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME || 'maorimaori',
  linkResolver: (input) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return linkResolver as any;
  },
});
